import React from 'react';
import styled from '@emotion/styled';
import { COLOR } from '../styles/variables';
import { buttonHoverStyle } from '../styles/common';

type Props = {
  children: JSX.Element;
};

const Button = (props: Props): JSX.Element => {
  const { children } = props;

  return <DIV_Wrap>{children}</DIV_Wrap>;
};

const DIV_Wrap = styled.div`
  text-align: center;

  a {
    position: relative;
    display: block;
    padding: 12px;
    border: solid 1px ${COLOR.button.border};
    border-radius: 1000px;
    overflow: hidden;
    font-weight: bold;
    ${buttonHoverStyle};
  }
`;

export { Button };
