import React from 'react';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { MEDIA_QUERY, COLOR, SHADOW } from '../styles/variables';
import { containerStyle } from '../styles/common';
import { COMMON_TEXT } from '../utils/word';

type Props = {
  data: any;
};

const Hero = (props: Props): JSX.Element => {
  const { data } = props;

  return (
    <DIV_Component>
      <DIV_HeroInner>
        <DIV_HeroCatch>
          <H1_HeroCatchTitle>
            <SPAN_HeroCatchTitleWord>Be</SPAN_HeroCatchTitleWord>
            <SPAN_HeroCatchTitleWord>a</SPAN_HeroCatchTitleWord>
            <SPAN_HeroCatchTitleWord>FrontEnd</SPAN_HeroCatchTitleWord>
            <SPAN_HeroCatchTitleWord>Designer</SPAN_HeroCatchTitleWord>
          </H1_HeroCatchTitle>
          <P_HeroCatchText>{COMMON_TEXT.ABOUT}</P_HeroCatchText>
        </DIV_HeroCatch>

        <FIGURE_HeroImage>
          <Img
            fluid={data.hero.childImageSharp.fluid}
            alt=""
            style={{ height: '100%' }}
          />
        </FIGURE_HeroImage>
      </DIV_HeroInner>

      <DIV_Wave>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" />
        </svg>
      </DIV_Wave>
    </DIV_Component>
  );
};

const DIV_Component = styled.div`
  position: relative;
  padding: 40px 16px 80px;
  background-image: linear-gradient(
    90deg,
    ${COLOR.gradation.start} 0%,
    ${COLOR.gradation.middle} 49%,
    ${COLOR.gradation.end} 100%
  );

  @media (min-width: ${MEDIA_QUERY.large}) {
    padding: 12px 80px 100px;
  }
`;

const DIV_HeroInner = styled.div`
  ${containerStyle}
  text-align: center;

  @media (min-width: ${MEDIA_QUERY.large}) {
    display: flex;
    align-items: center;
  }
`;

const FIGURE_HeroImage = styled.figure`
  width: 280px;
  height: 280px;
  margin: 0 auto;

  @media (min-width: ${MEDIA_QUERY.large}) {
    width: 360px;
    height: 360px;
  }

  img {
    object-fit: cover;
  }
`;

const DIV_HeroCatch = styled.div`
  flex: 1;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-shadow: ${SHADOW.hero.catch};
  text-align: left;

  @media (min-width: ${MEDIA_QUERY.large}) {
    padding-right: 40px;
  }
`;

const H1_HeroCatchTitle = styled.h1`
  margin-bottom: 10px;
  font-size: 3.4rem;
  line-height: 1.2;
  color: ${COLOR.hero.title};
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${MEDIA_QUERY.large}) {
    font-size: 5.4rem;
    margin-bottom: 30px;
  }
`;

const SPAN_HeroCatchTitleWord = styled.span`
  margin-right: 0.3em;
`;

const P_HeroCatchText = styled.p`
  font-size: 1.4rem;
  color: ${COLOR.hero.text};

  @media (min-width: ${MEDIA_QUERY.large}) {
    font-size: 2rem;
  }
`;

const DIV_Wave = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;

  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 90px;
  }

  path {
    fill: ${COLOR.hero.wave};
  }
`;

export { Hero };
