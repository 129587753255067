import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Link from 'gatsby-link';
import Layout from '../components/Layout';
import { Hero } from '../components/Hero';
import { Seo } from '../components/Seo';
import { Button } from '../components/Button';
import { RecentArticle } from '../components/RecentArticle';
import { CommonBlock } from '../components/CommonBlock';
import { CommonContent } from '../components/CommonContent';
import { CategoryList } from '../components/CategoryList';
import { MODULE_TITLE } from '../utils/word';

export default ({ data }): JSX.Element => {
  return (
    <Layout>
      <Seo />
      <Hero data={data} />
      <CommonContent>
        <DIV_BlockWrapper>
          <CommonBlock title={MODULE_TITLE.RECENT_ARTICLE}>
            <RecentArticle />
            <DIV_ButtonWrap>
              <Button>
                <Link to="/article/">記事一覧</Link>
              </Button>
            </DIV_ButtonWrap>
          </CommonBlock>
        </DIV_BlockWrapper>

        <DIV_BlockWrapper>
          <CommonBlock title={MODULE_TITLE.ARTICLE_CATEGORY}>
            <CategoryList data={data.allContentfulCategory.nodes} />
          </CommonBlock>
        </DIV_BlockWrapper>
      </CommonContent>
    </Layout>
  );
};

const DIV_BlockWrapper = styled.div`
  margin-top: 40px;
`;

const DIV_ButtonWrap = styled.div`
  max-width: 300px;
  margin: 20px auto 0;
`;

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "img_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulCategory {
      nodes {
        category
        categorySlug
      }
    }
  }
`;
