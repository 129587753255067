import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ArticleList } from './ArticleList';

const RecentArticle = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(
        sort: { fields: publishDate, order: DESC }
        limit: 8
        skip: 0
      ) {
        edges {
          node {
            title
            slug
            id
            eyecatch {
              description
              fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return <ArticleList data={data} />;
};

export { RecentArticle };
